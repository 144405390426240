import React from "react";
import { cn } from "../../lib/utils";
import Image from "next/image";
import AutoplayVideo from "../home/autoplayVideo";

const content = [
  {
    title: "Auto-generate titles, prices, and item specs.",
    description:
      "We identify your item from a photo, and use real-time market insights to recommend a competitive listing price.",
    content: (
      <div className="relative inline-flex h-[500px] w-[235px] items-center">
        <Image
          src="/mockupsIdentify.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Toggle marketplaces"
        />
        {/* <div className="absolute left-[13px] top-[80px] z-10 h-[430px] w-[210px] overflow-hidden rounded-[28px]">
          <AutoplayVideo video="toggle" props="w-full h-full object-cover rounded-[42px]" size={900} />
        </div> */}
      </div>
    ),
    icon: "/visibility.svg",
    checkmark: "/iconCheckmarkDarkGray.png",
    features: ["Avoid manual listings", "Share personal details on condition", "Find useful pricing data"],

  },
  {
    title: "Get offers from verified buyers at ease",
    description: "Find verified buyers near you and avoid the hassle of shipping. No more scammers or endless marketplaces.",
    content: (
      <div className="flex h-[500px] w-[235px] items-center justify-center text-white">
        <Image
          src="/mockupsMessages.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Convenient shipping"
        />
      </div>
    ),
    icon: "/box.svg",
    checkmark: "/iconCheckmarkLightBlue.png",
    features: ["Verified buyers & sellers", "Choose distance preferences", "Pay by method of choice"],
  },
  {
    title: "See what your friends are selling nearby.",
    description:
      "Find items from verified sellers in your area. Browse listings from your social network and find great deals from people you know.",
    content: (
      <div className="flex h-[500px] w-[235px] items-center justify-center text-white">
        <Image
          src="/mockupsHome.png"
          width={320}
          height={720}
          className="h-full w-full object-cover"
          alt="Manage buyers"
        />
      </div>
    ),
    icon: "/crossPlatform.svg",
    checkmark: "/iconCheckmarkBlue.png",
    features: ["Find good deals nearby", "Buy from trusted sellers", "Community-powered secondhand"],
  },
];


const FeatureBreakdown = () => {
  return (
    <section className="flex justify-center bg-white py-24">
      <div className="flex h-full max-w-[1520px] flex-col items-start justify-start gap-8 px-12 md:px-24 lg:px-32 space-y-48">
        {content.map((item, index) => (
          <div key={item.title + index} className={`flex flex-col lg:flex-row w-full align-center justify-between space-y-24 lg:space-y-0 ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}>
            <div className={`flex flex-col space-y-3.5 w-full px-12 lg:w-1/2 ${index % 2 === 0 ? 'lg:pr-24' : 'lg:pl-24'}`}>
              <div className={`items-start pt-4 lg:pt-2`}>
                {item.icon && (
                  <Image draggable={false} src={item.icon} width={58} height={58} alt={`${item.title} icon`} />
                )}
              </div>
              <div className="flex flex-col space-y-5">
                <h2
                  className="font-sans text-[42px] font-semibold leading-[120%] text-neutral-950"
                  style={{ opacity: 1 }}
                >
                  {item.title}
                </h2>
                <p
                  className="max-w-[645px] text-left font-sans text-[18px] leading-normal text-darkGray"
                  style={{ opacity: 1 }}
                >
                  {item.description}
                </p>
                <div className="flex flex-col space-y-5">
                  {item.features && item.features.map((feature, featureIndex) => (
                    <div key={featureIndex} className="flex items-center space-x-2">
                      {item.checkmark && (
                        <Image 
                          draggable={false} 
                          src={item.checkmark} 
                          width={16} 
                          height={16} 
                          alt="Checkmark icon" 
                        />
                      )}
                      <p className={cn("text-neutral-950",
                        {
                          "text-sellRazeBlue": item.checkmark?.includes("iconCheckmarkBlue"),
                          "text-light-blue-100": item.checkmark?.includes("iconCheckmarkLightBlue"),
                          "text-darkGray/60": item.checkmark?.includes("iconCheckmarkDarkGray"),
                        }
                      )}>
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-[#fafafa] px-12 lg:px-10 py-12 lg:py-20 rounded-3xl lg:w-1/2 items-center align-center justify-center">
              {item.content}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default FeatureBreakdown;
